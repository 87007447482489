define('ember-route-helpers/utils/mount-point', ['exports', 'ember'], function (exports, _ember) {
  exports.prefixMountPoint = prefixMountPoint;
  exports.getMountPoint = getMountPoint;
  var isBlank = _ember['default'].isBlank;
  var typeOf = _ember['default'].typeOf;

  /**
   * Prefix a property (usually a routeName) with the mount point, if is an engine.
   *
   * @param  {String} mountPoint
   * @param  {String} propValue
   * @return {String}
   */

  function prefixMountPoint(mountPoint, propValue) {
    if (typeOf(propValue) !== 'string') {
      _ember['default'].assert('propValue argument must be an string', typeOf(propValue) !== 'string');
    }

    if (typeOf(mountPoint) !== 'string' || isBlank(mountPoint)) {
      return propValue;
    }

    if (propValue === 'application') {
      return mountPoint;
    }

    return mountPoint + '.' + propValue;
  }

  /**
   * Check if an element is into an engine and returns its mountPoint.
   *
   * @method getMountPoint
   * @param {Object} owner
   * @return {string|Boolean}. Mount point if is engine; false otherwise.
   */

  function getMountPoint(owner) {
    if (owner && typeof owner.mountPoint === 'string') {
      return owner.mountPoint;
    }

    return false;
  }
});