define('ember-paper-link/components/paper-link-item', ['exports', 'ember', 'ember-paper/components/paper-item', 'ember-paper-link/templates/components/paper-link-item'], function (exports, _ember, _emberPaperComponentsPaperItem, _emberPaperLinkTemplatesComponentsPaperLinkItem) {

  var PaperLinkItem = _emberPaperComponentsPaperItem['default'].extend({
    layout: _emberPaperLinkTemplatesComponentsPaperLinkItem['default'],
    hasPrimaryAction: true,
    replace: false
  });

  PaperLinkItem.reopenClass({
    positionalParams: 'params'
  });

  exports['default'] = PaperLinkItem;
});