define('paper-pikaday/components/paper-pikaday', ['exports', 'moment', 'ember-pikaday/mixins/pikaday', 'ember-paper/components/paper-input', 'ember-paper/templates/components/paper-input'], function (exports, _moment, _pikaday, _paperInput, _paperInput2) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var defaultFormat = 'YYYY-MM-DD';

	exports.default = _paperInput.default.extend(_pikaday.default, {
		layout: _paperInput2.default,

		useUTC: false,

		format: defaultFormat,

		init: function init() {
			this._super.apply(this, arguments);
			this.get('format') || this.set('format', defaultFormat);
		},
		didInsertElement: function didInsertElement() {
			if (this.isDestroyed) {
				return;
			}

			this._super.apply(this, arguments);

			var field = this.$('input').get(0);

			this.set('field', field);

			this.setupPikaday();
		},
		willDestroyElement: function willDestroyElement() {
			if (this.isDestroyed) {
				return;
			}

			this._super.apply(this, arguments);

			this.get('pikaday').destroy();

			this.set('field', null);
		},
		userSelectedDate: function userSelectedDate() {
			var selectedDate = this.get('pikaday').getDate();

			if (this.get('useUTC')) {
				selectedDate = _moment.default.utc([selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate()]).toDate();
			}

			var format = this.get('format') || this.set('format', defaultFormat);
			var formattedDate = (0, _moment.default)(selectedDate).format(format);
			this.sendAction('onChange', formattedDate);
		},
		onPikadayOpen: function onPikadayOpen() {
			this.sendAction('onOpen');
		},
		onPikadayClose: function onPikadayClose() {
			if (this.isDestroyed) {
				return;
			}

			if (this.get('pikaday').getDate() === null || Ember.isEmpty(this.$(this.field).val())) {
				this.sendAction('onChange', '');
			}

			this.sendAction('onClose');
		}
	});
});