define('ember-route-helpers/helpers/transition-to', ['exports', 'ember', 'ember-route-helpers/utils/query-params', 'ember-route-helpers/utils/mount-point'], function (exports, _ember, _emberRouteHelpersUtilsQueryParams, _emberRouteHelpersUtilsMountPoint) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  function _toArray(arr) { return Array.isArray(arr) ? arr : Array.from(arr); }

  var copy = _ember['default'].copy;
  var getOwner = _ember['default'].getOwner;
  exports['default'] = _ember['default'].Helper.extend({

    compute: function compute(_ref) {
      var _ref2 = _toArray(_ref);

      var params = _ref2;

      var _params = copy(params);
      var owner = getOwner(this);
      var router = owner.lookup('router:main');
      var queryParams = _params[_params.length - 1];
      var routeName = _params[0];

      // Prefix the route name with the engine's mountPoint, if exists
      _params[0] = (0, _emberRouteHelpersUtilsMountPoint.prefixMountPoint)((0, _emberRouteHelpersUtilsMountPoint.getMountPoint)(owner), routeName);

      if (queryParams instanceof _emberRouteHelpersUtilsQueryParams['default']) {
        _params[_params.length - 1] = { queryParams: queryParams.values };
      }

      return function (value) {
        router.transitionTo.apply(router, _toConsumableArray(_params));

        return value;
      };
    }

  });
});