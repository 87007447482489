define('ember-route-helpers/helpers/transition-to-external', ['exports', 'ember', 'buzz/utils/query-params'], function (exports, _ember, _buzzUtilsQueryParams) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  function _toArray(arr) { return Array.isArray(arr) ? arr : Array.from(arr); }

  var copy = _ember['default'].copy;
  var getOwner = _ember['default'].getOwner;

  /**
   * Given this "external routes":
   *
   * ```js
   * // host app
   * dependencies: {
   *   externalRoutes: {
   *     posts: 'blog.posts'
   *   }
   * }
   * ```
   *
   * ```js
   * // engine
   * dependencies: {
   *   externalRoutes: [
   *     'posts'
   *   ]
   * }
   * ```
   *
   * You can then use those external routes within a template:
   *
   * ```hbs
   * {{my-component onclick=(transition-to-external 'posts' (query-params id=3 comments=true)}}
   * ```
   */
  exports['default'] = _ember['default'].Helper.extend({

    compute: function compute(_ref) {
      var _ref2 = _toArray(_ref);

      var params = _ref2;

      var _params = copy(params);
      var owner = getOwner(this);
      var router = owner.lookup('router:main');
      var queryParams = _params[_params.length - 1];

      _params[0] = owner._getExternalRoute(_params[0]);

      if (queryParams instanceof _buzzUtilsQueryParams['default']) {
        _params[_params.length - 1] = { queryParams: queryParams.values };
      }

      return function (value) {
        router.transitionTo.apply(router, _toConsumableArray(_params));

        return value;
      };
    }

  });
});