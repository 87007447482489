define('ember-component-inbound-actions/helpers/send', ['exports', 'ember-helper'], function (exports, _emberHelper) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  function _toArray(arr) { return Array.isArray(arr) ? arr : Array.from(arr); }

  exports['default'] = (0, _emberHelper.helper)(function (_ref) {
    var _ref2 = _toArray(_ref);

    var target = _ref2[0];
    var action = _ref2[1];

    var params = _ref2.slice(2);

    return function () {
      target.send.apply(target, [action].concat(_toConsumableArray(params)));
    };
  });
});