define('ember-paper-link/components/paper-link', ['exports', 'ember', 'ember-paper/mixins/focusable-mixin', 'ember-paper/mixins/ripple-mixin', 'ember-paper/mixins/color-mixin', 'ember-paper/mixins/proxiable-mixin'], function (exports, _ember, _emberPaperMixinsFocusableMixin, _emberPaperMixinsRippleMixin, _emberPaperMixinsColorMixin, _emberPaperMixinsProxiableMixin) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].LinkComponent.extend(_emberPaperMixinsFocusableMixin['default'], _emberPaperMixinsRippleMixin['default'], _emberPaperMixinsColorMixin['default'], _emberPaperMixinsProxiableMixin['default'], {

    // Add needed paper-button properties
    classNames: ['md-default-theme', 'md-button', 'paper-link'],
    raised: false,
    iconButton: false,
    fab: computed.reads('mini'), // circular button
    mini: false,

    // For raised buttons: toggle disabled when button is active
    disabled: computed.and('raised', 'active'),

    // Ripple Overrides
    rippleContainerSelector: null,
    fitRipple: computed.readOnly('iconButton'),
    center: computed.readOnly('iconButton'),
    dimBackground: computed.not('iconButton'),

    classNameBindings: ['raised:md-raised', 'iconButton:md-icon-button', 'fab:md-fab', 'mini:md-mini'],

    // if passedParams then convert passedParams to link-to's expected positionalParams
    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('passedParams')) {
        this.set('params', this.get('passedParams'));
      }
    },

    // FocusableMixin overrides active so set back to link-to's active
    active: _ember['default'].LinkComponent.active,

    // FocusableMixin overrides to prevent changing 'active' property
    down: function down() {
      this.set('pressed', true);
    },
    up: function up() {
      this.set('pressed', false);
    }
  });
});