define('ember-cropperjs/components/image-cropper-call', ['exports', 'ember'], function (exports, _ember) {
  function _toArray(arr) { return Array.isArray(arr) ? arr : Array.from(arr); }

  var assert = _ember['default'].assert;
  var Component = _ember['default'].Component;
  var getProperties = _ember['default'].getProperties;

  var ImageCropperCallComponent = Component.extend({
    tagName: '',

    obj: null,
    func: null,
    args: null,
    onResp: null,
    params: null,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var _getProperties = getProperties(this, 'obj', 'func', 'args', 'params');

      var obj = _getProperties.obj;
      var func = _getProperties.func;
      var args = _getProperties.args;
      var params = _getProperties.params;

      if (args === null && params !== null) {
        if (func !== null) {
          args = params;
        } else {
          var _params = _toArray(params);

          func = _params[0];
          args = _params.slice(1);
        }
      }

      assert('image-cropper-call obj is required', typeof obj === 'object' && obj !== null);
      assert('image-cropper-call func is required and must be a string', typeof func === 'string');
      assert('image-cropper-call ' + func + ' must be a function on ' + obj, typeof obj[func] === 'function');

      this.sendAction('onResp', obj[func].apply(obj, args));
    }
  });

  ImageCropperCallComponent.reopenClass({
    positionalParams: 'params'
  });

  exports['default'] = ImageCropperCallComponent;
});