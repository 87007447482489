define('ember-cropperjs/components/image-cropper', ['exports', 'ember', 'ember-cropperjs/templates/components/image-cropper', 'cropperjs'], function (exports, _ember, _emberCropperjsTemplatesComponentsImageCropper, _cropperjs) {
  var Component = _ember['default'].Component;
  var copy = _ember['default'].copy;
  var get = _ember['default'].get;
  var run = _ember['default'].run;
  var set = _ember['default'].set;
  exports['default'] = Component.extend({
    classNames: ['image-cropper'],

    layout: _emberCropperjsTemplatesComponentsImageCropper['default'],

    cropper: null,
    options: null,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      run.scheduleOnce('afterRender', this, this._setup);
    },

    _setup: function _setup() {
      var image = document.getElementById('image-cropper-' + get(this, 'elementId'));
      var options = get(this, 'options');

      // Need a copy because Cropper does not seem to like the Ember EmptyObject that is created from the `{{hash}}` helper
      var opts = copy(options);

      var cropper = new _cropperjs['default'](image, opts);

      return set(this, 'cropper', cropper);
    }
  });
});