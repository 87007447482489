define('ember-cropperjs/components/image-cropper-on', ['exports', 'ember'], function (exports, _ember) {
  var _slice = Array.prototype.slice;
  var assert = _ember['default'].assert;
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var getProperties = _ember['default'].getProperties;
  var isPresent = _ember['default'].isPresent;
  var run = _ember['default'].run;

  var ImageCropperOnComponent = Component.extend({
    tagName: '',

    cropper: null,

    eventSource: null,
    event: null,
    action: null,

    _prevEvent: null,

    init: function init() {
      this._super.apply(this, arguments);

      this._boundOnEvent = run.bind(this, this._onEvent);
    },

    _normalizedEvent: computed('event', function () {
      var event = get(this, 'event');
      assert('image-cropper-event requires event to be a string, was ' + event, typeof event === 'string');

      return event.toLowerCase();
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var _getProperties = getProperties(this, 'eventSource', '_normalizedEvent', '_prevEvent', 'action');

      var eventSource = _getProperties.eventSource;
      var _normalizedEvent = _getProperties._normalizedEvent;
      var _prevEvent = _getProperties._prevEvent;
      var action = _getProperties.action;

      assert('image-cropper-event requires an eventSource', isPresent(eventSource));
      assert('image-cropper-event requires an action', isPresent(action));

      if (_normalizedEvent !== _prevEvent) {
        if (_prevEvent) {
          eventSource.removeEventListener(_prevEvent, this._boundOnEvent);
        }

        this._prevEvent = _normalizedEvent;
        eventSource.addEventListener(_normalizedEvent, this._boundOnEvent);
      }
    },

    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      var _getProperties2 = getProperties(this, 'eventSource', '_prevEvent');

      var eventSource = _getProperties2.eventSource;
      var _prevEvent = _getProperties2._prevEvent;

      if (eventSource && _prevEvent) {
        eventSource.removeEventListener(_prevEvent, this._boundOnEvent);
      }
    },

    _onEvent: function _onEvent() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      this.sendAction.apply(this, ['action', this.cropper].concat(_slice.call(arguments)));
    }
  });

  ImageCropperOnComponent.reopenClass({
    positionalParams: ['event', 'action']
  });

  exports['default'] = ImageCropperOnComponent;
});