define('ember-changeset/-private/relay', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object,
      get = Ember.get;
  exports.default = EmberObject.extend({
    changeset: null,
    key: null,

    init: function init() {
      this._changedKeys = [];
    },
    unknownProperty: function unknownProperty(key) {
      return this.changeset._valueFor(get(this, 'key') + '.' + key);
    },
    setUnknownProperty: function setUnknownProperty(key, value) {
      this._changedKeys.push(key);
      this.changeset._validateAndSet(get(this, 'key') + '.' + key, value);
      this.notifyPropertyChange(key);
      return value;
    },
    rollback: function rollback() {
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = this._changedKeys[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var key = _step.value;

          this.notifyPropertyChange(key);
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      this._changedKeys = [];
    },
    destroy: function destroy() {
      this._super.apply(this, arguments);
      this.changeset = null;
    }
  });
});